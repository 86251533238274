.overflow-auto {
    overflow: auto;
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-bottom: 1px solid $green-600;
    //-webkit-text-fill-color: green;
    //-webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}


.tab-space {
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
}

.expander-cell {
    width: 1%;
}

table td table {
    border: 1px solid gray;
}

text.highcharts-credits {
    display: none !important;
    content: '';
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgb(238, 238, 238);
    background-color: rgba(238, 238, 238, 0.8);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay-loader {
    position: relative;
    top: 25%;
    width: 100%;
    margin-top: 30px;
}

.overlay-content {
    position: relative;
    top: 36px;
    width: 100%;
    margin-top: 30px;
}

.overlay a.closebtn {
    padding: 2px;
    text-decoration: none;
    font-size: 48px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay a.closebtn:hover,
.overlay a.closebtn:focus {
    color: #484848;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

// @media (min-width: 991px) {
//     .overlay {
//         left: 260px;
//         right: 0;
//     }
// }

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }
}