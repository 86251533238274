.busy {
    /* Uncomment this to make it run! */
    /*
     animation: loader 5s linear infinite; 
  */
    position: relative;
    width: 100%;
    min-height: 100px;

    p {
        display: block;
        text-align: center;
        width: 100%;
        font-weight: 300;
        color: $gray;
        text-transform: lowercase;
        text-shadow: 2px 2px $gray-lighter;
    }

    .animation {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);

        @keyframes loader {
            0% {
                left: -100px
            }

            100% {
                left: 110%;
            }
        }

        .box {
            width: 50px;
            height: 50px;
            background: $brand-primary;
            animation: animate .5s linear infinite;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 3px;
        }

        @keyframes animate {
            17% {
                border-bottom-right-radius: 3px;
            }

            25% {
                transform: translateY(9px) rotate(22.5deg);
            }

            50% {
                transform: translateY(18px) scale(1, .9) rotate(45deg);
                border-bottom-right-radius: 40px;
            }

            75% {
                transform: translateY(9px) rotate(67.5deg);
            }

            100% {
                transform: translateY(0) rotate(90deg);
            }
        }

        .shadow {
            width: 50px;
            height: 5px;
            background: #000;
            opacity: 0.1;
            position: absolute;
            top: 59px;
            left: 0;
            border-radius: 50%;
            animation: shadow .5s linear infinite;
        }

        @keyframes shadow {
            50% {
                transform: scale(1.2, 1);
            }
        }

    }
}